import { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'

import { useStore } from 'zustand'

import { utcNever } from 'src/common'
import { Divider } from 'src/common/components/Divider'
import {
  ApiClientGetFilter,
  Entity,
  useFetchMany,
  useFetchManyByValue,
  useFetchOne,
  usePathname,
} from 'src/common/hooks'
import {
  IInkoperKlokView,
  IInkoperPlaatView,
  IKoper,
  InkoperView,
  type IUserAccountDayLimit,
} from 'src/common/services/client'
import { useLimitsStore } from 'src/features/purchaserLimits'

import { PurchaserCardsAndClocks, PurchaserDetails } from '../components'
import {
  PurchaserCardsSkeleton,
  PurchaserDetailsSkeleton,
} from '../components/skeletons'
import { useCardRowsStore, usePurchaserStore } from '../stores'
import { useBuyerStore } from '../stores/BuyerStore'

export default function ManagePurchaser(): JSX.Element {
  const history = useHistory()

  const { urlParam, upsertMode } = usePathname('purchasers')
  const {
    mustRefetchPurchaser,
    mustRefetchPurchaserCards,
    purchaser,
    resetPurchaserState,
    setMustRefetchPurchaser,
    setMustRefetchPurchaserCards,
    setOldPurchaser,
    setPurchaser,
    updatePurchaser,
    setPurchaserClocks,
    setPurchaserCards,
  } = usePurchaserStore()
  const resetCardRowsState = useStore(
    useCardRowsStore,
    state => state.resetCardRowsState
  )
  const { isLoadingLimits, setDayLimit, setIsLoadingLimits } = useLimitsStore()
  const {
    mustRefetchBuyer,
    buyerNumber,
    setMustRefetchBuyer,
    setBuyerName,
    setBuyerNumber,
    setBuyerGlnCode,
    resetBuyerState,
  } = useBuyerStore()

  const handleGoBack = useCallback(() => {
    history.goBack()
  }, [history])

  const purchaserId = urlParam
  const filter: ApiClientGetFilter = {
    key: purchaserId,
  }

  const {
    isLoading,
    data: fetchedPurchaser,
    refetch,
  } = useFetchOne<InkoperView>(
    filter,
    Entity.IInkoperView,
    Boolean(purchaserId)
  )

  const { data: fetchedPurchaserCards } = useFetchMany<IInkoperPlaatView>(
    {
      filter: `inkoperIdentificatie eq '${fetchedPurchaser?.inkoperIdentificatie}'`,
    },
    Entity.IInkoperPlaat,
    Boolean(fetchedPurchaser?.inkoperIdentificatie)
  )

  const { isLoading: isLoadingBuyer, data: fetchedBuyer } = useFetchOne<IKoper>(
    {
      key: buyerNumber,
    },
    Entity.IKoper,
    Boolean(buyerNumber) && mustRefetchBuyer
  )

  const {
    data: { records: fetchedClocks },
    refetch: refetchClocks,
  } = useFetchManyByValue<IInkoperKlokView>(
    { key: fetchedPurchaser?.inkoperIdentificatie },
    Entity.IInkoperKlokView,
    fetchedPurchaser?.inkoperIdentificatie != null,
    {
      initialData: {
        records: [],
      },
    }
  )

  const getDayLimitFromPurchaser = (
    newPurchaser: InkoperView
  ): Partial<IUserAccountDayLimit> => ({
    userAccountDayLimitId: newPurchaser?.inkoperLimietId ?? 0,
    userAccountName: newPurchaser?.inkoperIdentificatie,
    userAccountDayLimitAmount: newPurchaser?.inkoperLimiet ?? 0,
    endDateTime: newPurchaser?.limietEindDatum
      ? newPurchaser?.limietEindDatum
      : utcNever(),
  })

  useEffect(() => {
    if (fetchedPurchaser) {
      fetchedPurchaser.blokkeerIndicatie = fetchedPurchaser?.blokkeerIndicatie
        ? fetchedPurchaser.blokkeerIndicatie
        : false
      setOldPurchaser(fetchedPurchaser)
      setPurchaser(fetchedPurchaser)
      setBuyerNumber(fetchedPurchaser.koperNummer)
      setMustRefetchBuyer(true)
      if (!fetchedClocks.length) {
        refetchClocks()
      }
      if (!mustRefetchPurchaser && !isLoadingLimits) {
        const dayLimit = getDayLimitFromPurchaser(fetchedPurchaser)
        setDayLimit(dayLimit)
      }
      setMustRefetchPurchaser(false)
    }
  }, [
    fetchedClocks,
    fetchedPurchaser,
    isLoadingLimits,
    mustRefetchPurchaser,
    refetchClocks,
    setBuyerNumber,
    setDayLimit,
    setMustRefetchBuyer,
    setMustRefetchPurchaser,
    setOldPurchaser,
    setPurchaser,
  ])

  useEffect(() => {
    if (fetchedBuyer && mustRefetchBuyer) {
      setBuyerName(fetchedBuyer.kopernaam)
      setBuyerGlnCode(Number(fetchedBuyer.eancode))
      updatePurchaser({
        koperNummer: buyerNumber,
        koperNaam: fetchedBuyer.kopernaam,
      })
      setMustRefetchBuyer(false)
    }
  }, [
    buyerNumber,
    fetchedBuyer,
    mustRefetchBuyer,
    setBuyerGlnCode,
    setBuyerName,
    setMustRefetchBuyer,
    updatePurchaser,
  ])

  useEffect(() => {
    if (
      upsertMode === 'EDIT' &&
      (mustRefetchPurchaser || mustRefetchPurchaserCards)
    ) {
      refetch().then(() => {
        setPurchaser(fetchedPurchaser)
        setMustRefetchPurchaser(false)
        setMustRefetchPurchaserCards(false)
        setIsLoadingLimits(false)
      })
    }
    return () => {
      const goingAway =
        purchaser?.inkoperID && urlParam !== purchaser?.inkoperID
      if (goingAway && upsertMode === 'VOID') {
        console.log('Going away')
        resetBuyerState()
        resetCardRowsState()
        resetPurchaserState()
      }
    }
  }, [
    fetchedPurchaser,
    mustRefetchPurchaser,
    mustRefetchPurchaserCards,
    purchaser?.inkoperID,
    refetch,
    resetBuyerState,
    resetCardRowsState,
    resetPurchaserState,
    setMustRefetchPurchaser,
    setMustRefetchPurchaserCards,
    setPurchaser,
    upsertMode,
    urlParam,
  ])

  useEffect(() => {
    setPurchaserClocks(fetchedClocks ?? [])
  }, [fetchedClocks, setPurchaserClocks])

  useEffect(() => {
    if (fetchedPurchaserCards?.records?.length > 0) {
      setPurchaserCards(fetchedPurchaserCards?.records)
    }
  }, [fetchedPurchaserCards, setPurchaserCards])

  if (purchaserId && isLoading && isLoadingBuyer) {
    return (
      <Container>
        <Grid item xs={12}>
          {mustRefetchPurchaserCards ? (
            <PurchaserDetails onGoBack={handleGoBack} />
          ) : (
            <PurchaserDetailsSkeleton />
          )}
        </Grid>
        <Divider {...{ mt: 2 }} />
        <Grid item xs={12}>
          <PurchaserCardsSkeleton />
        </Grid>
      </Container>
    )
  }

  return (
    <Container>
      <Grid item xs={12}>
        <PurchaserDetails onGoBack={handleGoBack} />
      </Grid>
      <Divider {...{ mt: 2 }} />
      <Grid item xs={12}>
        <PurchaserCardsAndClocks />
      </Grid>
    </Container>
  )
}
