import { useEffect } from 'react'

import { useStore } from 'zustand'

import { buildVersion } from 'src/common/config/buildVersion.json'

import * as packageJson from '../../../package.json'
import {
  type Feature,
  featureList,
  type FeatureName,
  featureNames,
  FEATURES,
} from '../config'
import { useDevToolsStore } from '../stores'

type UseDevToolsReturn = {
  syncFeatures: () => void
  version: string
}

export default function useDevTools(): UseDevToolsReturn {
  const { setFeatures } = useStore(useDevToolsStore, state => ({
    setFeatures: state.setFeatures,
  }))
  const version = getBuildVersion()

  function syncFeatures() {
    const initState: Record<FeatureName, Feature> = { ...FEATURES }
    const keys = Object.keys(initState) as FeatureName[]
    // disable features that are missing in the list or permanently disabled
    featureList.forEach(feature => {
      if (!keys.includes(feature.name) || feature.alwaysEnabled) {
        initState[feature.name].enabled = false
      }
    })
    // remove features that are not in the list
    keys.forEach(key => {
      if (!featureNames.includes(key)) {
        delete initState[key]
      }
    })
    // sync the store with the updated list
    setFeatures(initState)
  }

  useEffect(() => {
    syncFeatures()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { syncFeatures, version }
}

function getBuildVersion(): string {
  const devVersion = packageJson.version
  const prdVersion = packageJson.version + (buildVersion || '')
  const version =
    process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test'
      ? devVersion
      : prdVersion
  return version
}
