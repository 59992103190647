import { useStore } from 'zustand'

import {
  useBuyersCardStore,
  useBuyerStore,
  useCardRowsStore,
  useLimitsStore,
  usePurchasersFilterStore,
  usePurchaserStore,
} from 'src/features'

export default function useResetStoreStates(): () => void {
  const resetPurchaserState = useStore(
    usePurchaserStore,
    state => state.resetPurchaserState
  )
  const resetPurchasersFilterState = useStore(
    usePurchasersFilterStore,
    state => state.resetPurchasersFilterState
  )
  const resetCardRowsState = useStore(
    useCardRowsStore,
    state => state.resetCardRowsState
  )
  const resetBuyerState = useStore(
    useBuyerStore,
    state => state.resetBuyerState
  )
  const resetBuyersCardState = useStore(
    useBuyersCardStore,
    state => state.resetBuyersCardState
  )
  const resetLimitsFilterState = useStore(
    useLimitsStore,
    state => state.resetLimitsState
  )

  function resetAllStoreStates() {
    resetPurchaserState()
    resetPurchasersFilterState()
    resetCardRowsState()
    resetBuyerState()
    resetBuyersCardState()
    resetLimitsFilterState()
  }

  return resetAllStoreStates
}
