import { type FormEvent, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { useStore } from 'zustand'

import {
  Entity,
  type IInkoper,
  snackbarUtils,
  type UpsertModeType,
  useAddEntity,
  usePathname,
  useUpdateEntityByKey,
  utcNever,
} from 'src/common'
import { useLimitsStore, usePurchaserLimit } from 'src/features/purchaserLimits'

import { removeExtraIInkoperViewProperties } from '../lib'
import { usePurchaserStore } from '../stores'

type UsePurchaserReturn = {
  busyUpserting: boolean
  submitPurchaser: (event: FormEvent<HTMLFormElement>) => Promise<void>
}

export default function usePurchaser(): UsePurchaserReturn {
  const { t } = useTranslation()
  const history = useHistory()
  const { upsertMode }: { upsertMode: UpsertModeType } =
    usePathname('purchasers')
  const addEntity = useAddEntity<IInkoper>(Entity.IInkoper)
  const updateEntityByKey = useUpdateEntityByKey<IInkoper>(Entity.IInkoper)
  const { submitPurchaserLimit } = usePurchaserLimit()
  const isPurchaserLimitDirty = useStore(
    useLimitsStore,
    state => state.isPurchaserLimitDirty
  )
  const {
    isPurchaserDirty,
    purchaser,
    setMustRefetchPurchaser,
    updatePurchaser,
    userId,
  } = usePurchaserStore()
  const [busyUpserting, setBusyUpserting] = useState<boolean>(false)

  const initPurchaserToUpsert = useCallback(() => {
    // remove inkoperPlatenLijst, koperNaam, locatieOmschrijving
    const toUpsert: IInkoper = removeExtraIInkoperViewProperties(purchaser)
    toUpsert.inkoperPlaten = []
    toUpsert.mutatieDatumTijd = new Date()
    toUpsert.mutatieGebruiker = userId
    toUpsert.blokkerenDatumTijd = purchaser?.blokkerenDatumTijd
    toUpsert.vrijgaveDatumTijd = purchaser?.vrijgaveDatumTijd
    toUpsert.blokkeerRedenCode = purchaser?.blokkeerRedenCode || undefined
    toUpsert.opmerking = purchaser?.opmerking
    return toUpsert
  }, [purchaser, userId])

  const handleInsertPurchaser = useCallback(
    async (purchaserToUpsert: IInkoper) => {
      purchaserToUpsert.inkoperID = 0
      purchaserToUpsert.vervallenDatumTijd = utcNever()
      purchaserToUpsert.aanmaakDatumTijd = new Date()
      purchaserToUpsert.aanmaakGebruiker = userId
      updatePurchaser(purchaserToUpsert) // optimistic state update
      const addedPurchaser = await addEntity.mutateAsync(purchaserToUpsert)
      snackbarUtils.success(t('purchaser.upsert.added'))
      history.replace(`/dashboard/purchasers/${addedPurchaser.inkoperID}`)
    },
    [userId, updatePurchaser, addEntity, t, history]
  )

  const handleUpdatePurchaser = useCallback(
    async (purchaserId: number, purchaserToUpsert: Partial<IInkoper>) => {
      if (isPurchaserDirty()) {
        await updateEntityByKey.mutateAsync({
          key: purchaserId,
          body: purchaserToUpsert,
        })
      }
      if (isPurchaserLimitDirty()) {
        await submitPurchaserLimit(purchaserToUpsert.inkoperIdentificatie)
      }
      snackbarUtils.success(t('purchaser.upsert.saved'))
    },
    [
      isPurchaserDirty,
      updateEntityByKey,
      isPurchaserLimitDirty,
      submitPurchaserLimit,
      t,
    ]
  )

  const submitPurchaser = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault()

      // avoid concurrent submits or no inkoperID when editing
      if (
        busyUpserting ||
        (upsertMode === 'EDIT' && !purchaser?.inkoperID) ||
        addEntity.isLoading ||
        updateEntityByKey.isLoading
      ) {
        return Promise.resolve()
      }

      setBusyUpserting(true)
      const purchaserId = purchaser?.inkoperID ?? 0

      const purchaserToUpsert: IInkoper = initPurchaserToUpsert()
      updatePurchaser({
        mutatieDatumTijd: new Date(),
        mutatieGebruiker: userId,
      })

      try {
        if (upsertMode === 'ADD') {
          await handleInsertPurchaser(purchaserToUpsert)
        } else {
          /* EDITING */
          await handleUpdatePurchaser(purchaserId, purchaserToUpsert)
        }
      } catch (error) {
        snackbarUtils.error(`${error}`)
      } finally {
        setBusyUpserting(false)
        setMustRefetchPurchaser(true)
      }
    },
    [
      busyUpserting,
      upsertMode,
      purchaser,
      addEntity?.isLoading,
      updateEntityByKey?.isLoading,
      initPurchaserToUpsert,
      updatePurchaser,
      userId,
      handleInsertPurchaser,
      handleUpdatePurchaser,
      setMustRefetchPurchaser,
    ]
  )

  return { busyUpserting, submitPurchaser }
}
