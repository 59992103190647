import { SnackbarProvider } from 'notistack'

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { BrowserRouter as Router } from 'react-router-dom'

import { Rainbow } from '@con/ui'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { Footer } from '@rfh-core/components'
import * as Sentry from '@sentry/react'

import Package from './../package.json'
import './App.css'
import AppBar from './common/components/AppBar/AppBar'
import GenericError from './common/components/GenericError'
import { Config } from './common/config'
import { GlobalProvider } from './common/providers/GlobalProvider'
import useDevTools from './dev-tools/hooks/useDevTools'
import i18n, { type SupportedLanguage } from './i18n'
import { Routes } from './routes'

export default function App(): JSX.Element {
  const { t } = useTranslation()
  const { version } = useDevTools()
  const zIndex = 1500

  useEffect(() => {
    document.title = `RFH - ${t('common.applicationName')}`
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language])

  return (
    <Sentry.ErrorBoundary
      fallback={
        <GenericError error={new Error(t('common.genericErrorShort'))} />
      }
    >
      <SnackbarProvider
        autoHideDuration={7000}
        maxSnack={5}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Router>
          <GlobalProvider>
            <Box position={'fixed'} top={14} right={80} zIndex={1500}>
              <Typography variant='overline'>V {version}</Typography>
            </Box>
            <Box
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'space-between'}
              minHeight={'100vh'}
            >
              <AppBar />
              <Routes />
              <Footer
                maxWidth='lg'
                language={i18n.language.split('-')[0] as SupportedLanguage}
              />
              <Box
                position={'fixed'}
                left={0}
                right={0}
                bottom={0}
                zIndex={zIndex}
              >
                <Rainbow />
              </Box>
            </Box>
          </GlobalProvider>
        </Router>
      </SnackbarProvider>
    </Sentry.ErrorBoundary>
  )
}

Sentry.init({
  dsn: 'https://97dcb8125aa9130373867086dd8cdb88@o902103.ingest.us.sentry.io/4507100616589312',
  environment: Config.env,
  release: Package.version,
  enabled: process.env.NODE_ENV !== 'development',
  integrations: [
    Sentry.reactRouterV5BrowserTracingIntegration({ history }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
})
