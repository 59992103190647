import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'

import { IconButton } from '@mui/material'
import {
  type GridCellParams,
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid-premium'
import { DeleteIcon, EditIcon } from '@rfh-core/icons'
import theme from '@rfh-core/theme'

import { CustomDataGrid } from 'src/common'
import { IInkoperView } from 'src/common/services/client'
import { useUserStore } from 'src/common/stores/UserStore'
import { useLimitsStore } from 'src/features/purchaserLimits'
import i18n from 'src/i18n/index'

import { purchasersColumns } from '../constants'
import { DeletePurchaserDialog } from './DeletePurchaserDialog'

type PurchasersGridProps = {
  isLoading: boolean
  refresh: () => void
  rows: IInkoperView[]
  totalCount: number
}

export default function PurchasersGrid({
  isLoading,
  refresh,
  rows,
  totalCount,
}: Readonly<PurchasersGridProps>): JSX.Element {
  const { t } = useTranslation()
  const { user } = useUserStore()
  const { resetLimitsState } = useLimitsStore()
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [idToBeDeleted, setIdToBeDeleted] = useState<number>(0)
  const history = useHistory()
  const getRowId = useCallback((row: IInkoperView) => row.inkoperID, [])

  const openDeleteDialog = (value: IInkoperView) => {
    setOpenDeleteModal(true)
    setIdToBeDeleted(value.inkoperID)
  }

  const closeDeleteDialog = useCallback(() => {
    setOpenDeleteModal(false)
    setIdToBeDeleted(0)
  }, [])

  const gotoPurchaserDetails = useCallback(
    (params: GridCellParams<any, IInkoperView, any>) => {
      if (params?.row?.inkoperID === undefined) {
        return
      }

      // Navigate to the details unless the checkbox cell, delete or edit button is clicked
      if (
        params?.field !== '__check__' &&
        params?.field !== 'bewerken' &&
        params?.field !== 'delete'
      ) {
        history.push(`/dashboard/purchasers/${params.row.inkoperID}`)
      }
    },
    [history]
  )

  useEffect(() => {
    resetLimitsState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getColumns = useCallback((): GridColDef[] => {
    const staticColumns = [...purchasersColumns]
    if (user.isContractBeheerder || user.isApplicationManager) {
      staticColumns.push({
        field: 'bewerken',
        type: 'actions',
        headerName: t('common.edit'),
        headerAlign: 'center',
        align: 'center',
        disableColumnMenu: true,
        hideable: false,
        sortable: false,
        disableExport: true,
        renderHeader: () => (
          <EditIcon
            sx={{
              color: `${theme.rfhColors.white}`,
              width: '20px',
              height: '20px',
            }}
          />
        ),
        renderCell: (params: GridRenderCellParams<any, IInkoperView>) =>
          new Date(params.row.vervallenDatumTijd) > new Date() ? (
            <IconButton
              component={Link}
              to={`/dashboard/purchasers/${params.row.inkoperID}`}
            >
              <EditIcon
                data-testid='edit'
                sx={{
                  cursor: 'pointer',
                  color: `${theme.rfhColors.grey[800]}`,
                  width: '20px',
                  height: '20px',
                }}
              />
            </IconButton>
          ) : (
            <EditIcon
              data-testid='edit'
              sx={{
                cursor: 'pointer',
                color: `${theme.rfhColors.grey[100]}`,
                width: '20px',
                height: '20px',
              }}
            />
          ),
        flex: 0.5,
      })
      staticColumns.push({
        field: 'delete',
        type: 'actions',
        headerName: t('common.delete'),
        headerAlign: 'center',
        align: 'center',
        disableColumnMenu: true,
        hideable: false,
        sortable: false,
        disableExport: true,
        renderHeader: () => (
          <DeleteIcon
            sx={{
              color: theme.rfhColors.white,
              width: '20px',
              height: '20px',
            }}
          />
        ),
        renderCell: (params: GridRenderCellParams) => (
          <IconButton onClick={() => openDeleteDialog(params.row)}>
            <DeleteIcon
              data-testid='delete'
              sx={{
                cursor: 'pointer',
                color: `${theme.rfhColors.grey[800]}`,
                width: '20px',
                height: '20px',
              }}
            />
          </IconButton>
        ),
        flex: 0.5,
      })
    }

    return staticColumns
  }, [t, user.isApplicationManager, user.isContractBeheerder])

  return (
    <>
      <CustomDataGrid
        columns={getColumns()}
        getRowId={getRowId}
        gridName={'purchasers'}
        gridProps={{
          export: {
            title: t('purchaser.purchasers'),
          },
          loading: isLoading,
          rowCount: totalCount,
          onCellClick: gotoPurchaserDetails,
        }}
        language={i18n.language}
        rows={rows}
      />
      <DeletePurchaserDialog
        idToBeDeleted={idToBeDeleted}
        onCancel={closeDeleteDialog}
        onClose={closeDeleteDialog}
        open={openDeleteModal}
        onRefresh={refresh}
      />
    </>
  )
}
