import { formatInTimeZone } from 'date-fns-tz'
import { t } from 'i18next'

import { GridValueFormatterParams } from '@mui/x-data-grid-premium'
import { GridGroupingValueGetterParams } from '@mui/x-data-grid-premium/models/gridGroupingValueGetterParams'

export const getUTCDate = (value: Date): Date =>
  new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate()))

export const utcToday = (): Date => getUTCDate(new Date())

export const utcNever = (): Date => getUTCDate(new Date('2099-12-31T23:59:59'))

export const isDateToday = (date: Date): boolean => {
  if (!date) return false

  const dateString = format(getUTCDate(new Date(date)), 'dd-MM-yyyy')
  const dateNowString = format(getUTCDate(new Date()), 'dd-MM-yyyy')
  return dateString === dateNowString
}

export const showDateTime = (date: Date): string => {
  if (date) {
    return formatInTimeZone(date, 'Europe/Amsterdam', t('common.dateFormat'))
  }

  return t('common.notApplicable')
}

/**
 * Example: 11:08:20.488
 * @returns string
 */
export const getTimeStampNow = (): string =>
  new Date().toISOString().split('T')[1].slice(0, -1)

export const format = (value: Date, dateformat: string): string =>
  formatInTimeZone(value, 'UTC', dateformat)

export const formatDate = (
  maybeDate: Date | GridGroupingValueGetterParams | GridValueFormatterParams,
  dateFormat: string
): string => {
  if (!maybeDate) {
    console.log('tak 1')
    return '-'
  }
  if (maybeDate instanceof Date) {
    console.log('tak 2')
    return format(maybeDate, dateFormat)
  }
  // GridGroupingValueGetterParams | GridValueFormatterParams
  if (!maybeDate.value) {
    console.log('tak 3')
    return '-'
  }
  const date = new Date(maybeDate.value)
  return format(date, dateFormat)
}
