import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Button, Grid, Typography } from '@mui/material'

import { useStore } from 'zustand'

import { ThemeConfig } from 'src/common/config/SpacingConfig'
import { Entity, usePathname, useUpdateEntity } from 'src/common/hooks'
import { UpsertMode } from 'src/common/index'
import {
  IFloridayUser,
  IMigratieInkoper,
  MigratieInkoper,
} from 'src/common/services/client'
import { useLimitsStore } from 'src/features/purchaserLimits'

import { usePurchaser } from '../../hooks'
import {
  useBuyerStore,
  useCardRowsStore,
  usePurchaserStore,
} from '../../stores'
import { FloridaySearchDialog } from '../FloridaySearchDialog'
import BlockingFields from './BlockingFields'
import GeneralFields from './GeneralFields'
import LimitFields from './LimitFields'

type PurchaserDetailsProps = {
  onGoBack: () => void
}

export default function PurchaserDetails({
  onGoBack,
}: Readonly<PurchaserDetailsProps>): JSX.Element {
  const oneThird = String(100 / 3) + '%'
  const { t } = useTranslation()
  const { upsertMode } = usePathname('purchasers')
  const migratePurchaser = useUpdateEntity<IMigratieInkoper>(
    Entity.IInkoperView
  )
  const { busyUpserting, submitPurchaser } = usePurchaser()
  const {
    isPurchaserDirty,
    purchaser,
    resetPurchaserState,
    updatePurchaser,
    openFloridaySearch,
    setOpenFloridaySearch,
  } = usePurchaserStore()
  const { buyerName, resetBuyerState, setBuyerName, setBuyerNumber } =
    useBuyerStore()
  const resetCardRowsState = useStore(
    useCardRowsStore,
    state => state.resetCardRowsState
  )
  const { dayLimit, isPurchaserLimitDirty } = useLimitsStore()
  const [disabled, setDisabled] = useState(true)

  const handleClickOpen = () => {
    setOpenFloridaySearch(true)
  }

  const isFormFilled = useCallback(
    () =>
      Boolean(purchaser?.inkoperIdentificatie) &&
      buyerName && // valid buyer
      purchaser?.koperNummer > 0 &&
      Boolean(purchaser?.locatieCode),
    [purchaser, buyerName]
  )

  const handleGoBack = () => {
    resetPurchaserState()
    resetCardRowsState()
    resetBuyerState()
    onGoBack()
  }

  const handleFloridaySelection = (floridayUser: IFloridayUser) => {
    if (floridayUser?.koper) {
      setBuyerName(floridayUser?.koper?.kopernaam)
      setBuyerNumber(floridayUser?.koper?.kopernummer)
      if (upsertMode === UpsertMode.add) {
        updatePurchaser({
          inkoperIdentificatie: floridayUser.alternateId,
          koperNaam: floridayUser.koper.kopernaam,
          koperNummer: floridayUser.koper.kopernummer,
          sourceCode: 'FDY',
        })
      } else {
        updatePurchaser({
          inkoperIdentificatie: floridayUser.alternateId,
          koperNaam: floridayUser.koper.kopernaam,
          koperNummer: floridayUser.koper.kopernummer,
          alias: purchaser.inkoperIdentificatie,
          sourceCode: 'FDY',
        })
        const toMigrate = {
          alias: purchaser.inkoperIdentificatie,
          inkoperId: purchaser.inkoperID,
          inkoperIdentificatie: floridayUser.alternateId,
        } as MigratieInkoper
        migratePurchaser.mutate(toMigrate)
      }
    }
  }

  useEffect(() => {
    setDisabled(
      !isFormFilled() ||
        !(isPurchaserDirty() || isPurchaserLimitDirty()) ||
        busyUpserting
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dayLimit, purchaser])

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography
            variant='h1'
            sx={{
              fontSize: '2.5rem',
              my: `${ThemeConfig.spacing.sm * 8}px`,
            }}
          >
            {upsertMode === 'ADD'
              ? t('common.titles.purchaserAdd')
              : t('common.titles.purchaser')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box
            component='form'
            onSubmit={submitPurchaser}
            sx={{ marginTop: ThemeConfig.spacing.sm, width: '100%' }}
          >
            <Grid
              container
              flexDirection={'row'}
              justifyContent={'space-between'}
            >
              <Grid
                item
                sx={{
                  width: oneThird,
                  borderRight: theme => `1px solid ${theme.palette.grey[300]}`,
                  paddingRight: ThemeConfig.spacing.sm,
                }}
              >
                <GeneralFields />
              </Grid>
              <Grid
                item
                sx={{
                  width: oneThird,
                  borderRight: theme => `1px solid ${theme.palette.grey[300]}`,
                  paddingX: ThemeConfig.spacing.sm,
                }}
              >
                <BlockingFields />
              </Grid>
              <Grid
                item
                sx={{
                  width: oneThird,
                  paddingX: ThemeConfig.spacing.sm,
                }}
              >
                <LimitFields />
              </Grid>
            </Grid>
            {/* buttons */}
            <Grid
              container
              sx={{
                marginTop: ThemeConfig.spacing.sm,
                marginBottom: ThemeConfig.spacing.m,
              }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
                gap={ThemeConfig.spacing.s}
              >
                <Button
                  variant='outlined'
                  sx={{
                    paddingX: ThemeConfig.spacing.sm,
                  }}
                  onClick={handleGoBack}
                >
                  {t('common.back')}
                </Button>
                {upsertMode === UpsertMode.edit &&
                  purchaser?.sourceCode === 'RRU' && (
                    <Button
                      variant='contained'
                      disabled={busyUpserting}
                      sx={{
                        paddingX: ThemeConfig.spacing.sm,
                      }}
                      onClick={handleClickOpen}
                    >
                      {t('purchaser.migratePurchaser')}
                    </Button>
                  )}
                <Button
                  type='submit'
                  variant='contained'
                  disabled={disabled}
                  sx={{
                    paddingX: ThemeConfig.spacing.sm,
                  }}
                >
                  {upsertMode === 'ADD' ? t('common.create') : t('common.save')}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <FloridaySearchDialog
        open={openFloridaySearch}
        setOpen={setOpenFloridaySearch}
        onChange={handleFloridaySelection}
      />
    </>
  )
}
