import { Checkbox } from '@mui/material'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium'
import { AlertOIcon } from '@rfh-core/icons'
import theme from '@rfh-core/theme'

import {
  formatDate,
  formatNumber,
  IPurchaserFilterInput,
  toPascalCase,
} from 'src/common'
import {
  IInkoperPlaat,
  IInkoperView,
  IKoper,
  Vestiging,
} from 'src/common/services/client'
import i18n from 'src/i18n'

import { formatSource, purchaserCardsFormatter } from '../lib'
import type { FilterFields, PurchaserCardsGridColumnProps } from '../types'

export const ROOT_PATH = 'purchasers'

export const purchasersColumns: GridColDef[] = [
  {
    field: 'inkoperIdentificatie',
    headerName: i18n.t('purchaser.purchaserIdentification'),
    headerAlign: 'left',
    align: 'left',
    flex: 2.3,
  },
  {
    field: 'koperNummer',
    headerName: i18n.t('purchaser.buyerNumber'),
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    flex: 1,
    valueFormatter: row => formatNumber(row),
  },
  {
    field: 'koperNaam',
    headerName: i18n.t('purchaser.buyerName'),
    headerAlign: 'left',
    align: 'left',
    flex: 1.5,
  },
  {
    field: 'blokkeerIndicatie',
    headerName: i18n.t('purchaser.blocked'),
    headerAlign: 'center',
    align: 'center',
    type: 'boolean',
    flex: 0.8,
    valueFormatter: ({ value }) => value, // Needed for csv exports to supply the boolean as `Yes` and `No`, it uses the value from the valueGetter
    valueGetter: ({ value }) =>
      value ? i18n.t('common.yes') : i18n.t('common.no'), // Needed for excel exports to supply the boolean as `Yes` and `No`
    renderCell: (params: GridRenderCellParams) =>
      params.row.blokkeerIndicatie ? (
        <AlertOIcon
          sx={{
            color: `${theme.rfhColors.alertRed}`,
          }}
        />
      ) : (
        '-'
      ),
  },
  {
    field: 'blokkeerRedenOmschrijving',
    headerName: i18n.t('purchaser.blockingReason'),
    headerAlign: 'left',
    align: 'left',
    flex: 1.5,
  },
  {
    field: 'locatieOmschrijving',
    headerName: i18n.t('common.locationCode'),
    headerAlign: 'left',
    align: 'left',
    flex: 1,
  },
  {
    field: 'vervallenDatumTijd',
    headerName: i18n.t('purchaser.discardDateTime'),
    headerAlign: 'left',
    type: 'date',
    align: 'left',
    flex: 1.5,
    valueFormatter: row => formatDate(row, i18n.t('common.dateFormatShort')),
  },
  {
    field: 'zetelnummer',
    headerName: i18n.t('purchaser.seatNumber'),
    headerAlign: 'left',
    align: 'left',
    flex: 1,
    valueFormatter: row => formatNumber(row),
  },
  {
    field: 'sourceCode',
    headerName: i18n.t('purchaser.sourceCode'),
    headerAlign: 'center',
    align: 'center',
    flex: 0.8,
    valueFormatter: row => formatSource(row),
  },
  {
    field: 'inkoperPlaten',
    headerName: i18n.t('purchaser.purchaserCards'),
    headerAlign: 'left',
    align: 'left',
    flex: 2,
    valueFormatter: row => purchaserCardsFormatter(row.value),
    sortable: false,
  },
]

export const DefaultFilterValues: FilterFields = {
  purchaserIdentification: undefined,
  purchaserName: undefined,
  purchaserNumber: undefined,
}

export const defaultBuyerValues: IKoper = {
  blokkeerindicatie: undefined,
  eancode: undefined,
  einddatum: undefined,
  kopernaam: undefined,
  kopernummer: undefined,
  relatienummer: undefined,
}

export const defaultPurchaserValues: IInkoperView = {
  aanmaakDatumTijd: new Date(),
  aanmaakGebruiker: undefined,
  blokkeerIndicatie: undefined,
  inkoperID: undefined,
  inkoperIdentificatie: undefined,
  inkoperPlaten: undefined,
  koperNummer: undefined,
  locatieCode: undefined,
  mutatieDatumTijd: new Date(),
  mutatieGebruiker: undefined,
  vervallenDatumTijd: undefined,
  wachtwoord: undefined,
  zetelnummer: undefined,
}

export const getPurchaserCardsGridColumns = ({
  onCheckboxClick,
}: PurchaserCardsGridColumnProps): GridColDef[] => [
  {
    field: 'koperPlaatNummer',
    headerName: i18n.t('common.buyersCard'),
    headerAlign: 'center',
    align: 'center',
    flex: 1.2,
    sortable: false,
  },
  {
    field: 'koperNummer',
    headerName: i18n.t('purchaser.buyerNumber'),
    headerAlign: 'center',
    align: 'center',
    flex: 1.5,
    sortable: false,
  },
  {
    field: 'koperNaam',
    headerName: i18n.t('purchaser.buyerName'),
    headerAlign: 'left',
    align: 'left',
    flex: 4,
    sortable: false,
  },
  {
    field: Vestiging.Aalsmeer,
    headerName: toPascalCase(Vestiging.Aalsmeer),
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    sortable: false,
    valueFormatter: ({ value }) => value, // Needed for csv exports to supply the boolean as `Yes` and `No`, it uses the value from the valueGetter
    valueGetter: ({ value }) =>
      value ? i18n.t('common.yes') : i18n.t('common.no'), // Needed for excel exports to supply the boolean as `Yes` and `No`
    renderCell: (params: GridRenderCellParams) => (
      <Checkbox
        disabled={params.row.aalsmeerDisabled}
        checked={params.row.aalsmeer}
        onChange={() => onCheckboxClick(params.row, Vestiging.Aalsmeer)}
      />
    ),
  },
  {
    field: Vestiging.Naaldwijk,
    headerName: toPascalCase(Vestiging.Naaldwijk),
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    sortable: false,
    valueFormatter: ({ value }) => value, // Needed for csv exports to supply the boolean as `Yes` and `No`, it uses the value from the valueGetter
    valueGetter: ({ value }) =>
      value ? i18n.t('common.yes') : i18n.t('common.no'), // Needed for excel exports to supply the boolean as `Yes` and `No`
    renderCell: (params: GridRenderCellParams) => (
      <Checkbox
        disabled={params.row.naaldwijkDisabled}
        checked={params.row.naaldwijk}
        onChange={() => onCheckboxClick(params.row, Vestiging.Naaldwijk)}
      />
    ),
  },
  {
    field: Vestiging.Rijnsburg,
    headerName: toPascalCase(Vestiging.Rijnsburg),
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    sortable: false,
    valueFormatter: ({ value }) => value, // Needed for csv exports to supply the boolean as `Yes` and `No`, it uses the value from the valueGetter
    valueGetter: ({ value }) =>
      value ? i18n.t('common.yes') : i18n.t('common.no'), // Needed for excel exports to supply the boolean as `Yes` and `No`
    renderCell: (params: GridRenderCellParams) => (
      <Checkbox
        disabled={params.row.rijnsburgDisabled}
        checked={params.row.rijnsburg}
        onChange={() => onCheckboxClick(params.row, Vestiging.Rijnsburg)}
      />
    ),
  },
  {
    field: Vestiging.Eelde,
    headerName: toPascalCase(Vestiging.Eelde),
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    sortable: false,
    valueFormatter: ({ value }) => value, // Needed for csv exports to supply the boolean as `Yes` and `No`, it uses the value from the valueGetter
    valueGetter: ({ value }) =>
      value ? i18n.t('common.yes') : i18n.t('common.no'), // Needed for excel exports to supply the boolean as `Yes` and `No`
    renderCell: (params: GridRenderCellParams) => (
      <Checkbox
        disabled={params.row.eeldeDisabled}
        checked={params.row.eelde}
        onChange={() => onCheckboxClick(params.row, Vestiging.Eelde)}
      />
    ),
  },
]

export const defaultPurchaserCardValues: IInkoperPlaat = {
  inkoperIdentificatie: undefined,
  inkoperPlaatID: undefined,
  koperPlaatNummer: undefined,
  mutatieDatumTijd: new Date(),
  mutatieGebruiker: undefined,
  vestigingCode: undefined,
}

export const SourceValues = {
  FDY: 'Floriday',
  RRU: 'Rru',
} as const

export const defaultFilter: IPurchaserFilterInput = {
  inkoperIdentificatie: undefined,
  koperNaam: undefined,
  koperNummer: undefined,
  inkoperPlatenLijst: undefined,
  sourceCode: undefined,
  blokkeerIndicatie: undefined,
  vervalIndicatie: undefined,
}
