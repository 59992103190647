import type { ReactNode } from 'react'

import { Box, Skeleton } from '@mui/material'

export default function LocationsSkeleton(): ReactNode {
  return (
    <Box display={'flex'} gap={2}>
      <Skeleton variant='rectangular' width={'35%'} height={40} />
      <Skeleton variant='rectangular' width={'65%'} height={40} />
    </Box>
  )
}
