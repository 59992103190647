import {
  generateDefaultFilterString,
  type ILimitsFilterInput,
} from 'src/common'

export const generateFilterString = (
  key: keyof ILimitsFilterInput,
  value: ILimitsFilterInput[keyof ILimitsFilterInput],
  filterRules: string[]
): string[] => {
  if (key == null || value == null) {
    return filterRules
  }
  if (key === 'userAccountName') {
    filterRules.push(`(contains(${key},'${value}'))`)
  } else {
    filterRules = generateDefaultFilterString(key, value as any, filterRules)
  }

  return filterRules
}

export const isInputValid = (name: string, value: string | Date): boolean => {
  switch (name) {
    case 'inkoperLimiet':
      return /^\d{0,6}$/.test(value as string)
    case 'endDate':
      return !isNaN((value as Date).getTime())
    default:
      return true
  }
}
