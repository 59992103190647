import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Grid, Switch } from '@mui/material'

import { ThemeConfig } from 'src/common/config/SpacingConfig'
import { RoleOptions, useUserStore } from 'src/common/stores/UserStore'

import {
  StyledRfhTypographyContent,
  StyledRfhTypographyTitle,
} from '../ProfilePage.styles'

type OverRulingProps = {
  minHeight: number
}

export default function OverRuling({
  minHeight,
}: Readonly<OverRulingProps>): JSX.Element {
  const { user, setRole } = useUserStore()
  const { t } = useTranslation()

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = target
    setRole(name as RoleOptions, checked)
  }

  return (
    <Grid container flexDirection={'column'}>
      <Grid
        item
        xs={12}
        sx={{
          backgroundColor: 'white',
          padding: ThemeConfig.spacing.sm,
          minHeight,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <StyledRfhTypographyTitle variant='h4'>
              {t('profilePage.applicationManagerRole')}
            </StyledRfhTypographyTitle>
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledRfhTypographyContent
              data-testid='isApplicationManager'
              variant='subtitle1'
            >
              <Box
                sx={{
                  maxWidth: '50px',
                  minWidth: '50px',
                }}
              >
                <Switch
                  name='isApplicationManager'
                  checked={user.isApplicationManager}
                />
              </Box>
            </StyledRfhTypographyContent>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <StyledRfhTypographyTitle variant='h4'>
              {t('profilePage.accountManagementRole')}
            </StyledRfhTypographyTitle>
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledRfhTypographyContent
              data-testid='isAccountManagement'
              variant='subtitle1'
            >
              <Box
                sx={{
                  maxWidth: '50px',
                  minWidth: '50px',
                }}
              >
                <Switch
                  name='isAccountManagement'
                  checked={user.isAccountManagement}
                  onChange={handleChange}
                />
              </Box>
            </StyledRfhTypographyContent>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <StyledRfhTypographyTitle variant='h4'>
              {t('profilePage.contractManagementRole')}
            </StyledRfhTypographyTitle>
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledRfhTypographyContent
              data-testid='isContractBeheerder'
              variant='subtitle1'
            >
              <Box
                sx={{
                  maxWidth: '50px',
                  minWidth: '50px',
                }}
              >
                <Switch
                  name='isContractBeheerder'
                  checked={user.isContractBeheerder}
                  onChange={handleChange}
                />
              </Box>
            </StyledRfhTypographyContent>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
