import {
  createContext,
  type ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { Client, IClient } from 'src/common/services'

import { useUserStore } from '../stores/UserStore'

type ApiClientContextType = {
  apiClient: IClient
}

export const ApiClientContext = createContext<ApiClientContextType | null>(null)

/**
 * Use this hook to get the apiClient as a singleton
 * @example const {apiClient} = useApiClient()
 */
export const useApiClient = (): ApiClientContextType => {
  const apiClientContext = useContext(ApiClientContext)

  if (apiClientContext === undefined) {
    throw new Error(
      'useApiClient hook was used outside of its context provider'
    )
  }

  return apiClientContext
}

export const ApiClientProvider = ({
  children,
}: {
  children: ReactNode
}): JSX.Element => {
  const { user } = useUserStore()
  const [apiClient, setApiClient] = useState<IClient>()

  useEffect(() => {
    if (user?.accessToken) {
      console.log('apiClient renewed')
      const client = new Client(user.accessToken)
      setApiClient(client)
    } else {
      console.log('apiClient destroyed')
      setApiClient(undefined)
    }
  }, [user.accessToken])

  const returnProps = useMemo(
    () => ({
      apiClient,
    }),
    [apiClient]
  )

  return (
    <ApiClientContext.Provider value={returnProps}>
      {children}
    </ApiClientContext.Provider>
  )
}
