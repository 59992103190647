import { useTranslation } from 'react-i18next'
import {
  RouteProps,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom'

import { SecureRoute } from '@okta/okta-react'
import { LoadingPage } from '@rfh-core/components'

import { useUserStore } from 'src/common/stores/UserStore'

type ContractbeheerderRouteProps = RouteProps & { visible?: boolean }

export default function ContractbeheerderRoute({
  component: Component,
  visible = true,
  ...rest
}: Readonly<ContractbeheerderRouteProps>): JSX.Element {
  const { user } = useUserStore()
  const history = useHistory()
  const location = useLocation()
  const match = useRouteMatch()
  const { t } = useTranslation()

  const getContent = (props: any): JSX.Element => {
    if (!user.isAuthenticated) {
      return <LoadingPage label={t('common.loading')} />
    }

    // user is authenticated but the "visible" prop is passed as "false"
    // => nothing to do
    if (visible != null && !visible) {
      return
    }

    if (!(user.isContractBeheerder || user.isApplicationManager)) {
      console.warn(
        'Redirected unauthorized access to contract manager only route'
      )
      history.push('/dashboard')
    } else {
      return (
        <Component
          history={history}
          location={location}
          match={match}
          {...props}
        />
      )
    }
  }

  return (
    <SecureRoute
      {...(rest as any)}
      render={(props: any) => getContent(props)}
    />
  )
}
