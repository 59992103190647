export const FuncSort = {
  get: 'get',
  list: 'list',
  search: 'search',
  add: 'add',
  update: 'update',
  delete: 'delete',
} as const

export type FuncSortType = (typeof FuncSort)[keyof typeof FuncSort]

export const Entity = {
  BuyersCard: 'BuyersCard',
  BuyersCardView: 'BuyersCardView',
  FloridayUser: 'FloridayUser',
  IInkoper: 'IInkoper',
  IInkoperKlokView: 'IInkoperKlokView',
  IInkoperPlaat: 'IInkoperPlaat',
  IInkoperView: 'IInkoperView',
  IKoper: 'IKoper',
  ILocatie: 'ILocatie',
  IUserAccountDayLimit: 'IUserAccountDayLimit',
  IUserAccountDayLimitView: 'IUserAccountDayLimitView',
} as const

export type EntityType = (typeof Entity)[keyof typeof Entity]

export const ApiClientGetFunc = {
  getBuyersCard: 'buyersCardsGET',
  getBuyersCardView: 'buyersCardViews',
  getInkoperPlaat: 'inkoperPlatenGET',
  getInkoperView: 'inkoperViews',
  getKoper: 'kopers',
} as const

export type ApiClientGetFuncType =
  (typeof ApiClientGetFunc)[keyof typeof ApiClientGetFunc]

export const ApiClientListFunc = {
  listBuyersCard: 'odata_v1_BuyersCards',
  listBuyersCardView: 'odata_v1_BuyersCardViews',
  listFloridayUser: 'odata_v1_FloridayUsers',
  listInkoperPlaat: 'odata_v1_InkoperPlaten',
  listInkoperView: 'odata_v1_InkoperViews',
  listKoper: 'odata_v1_Kopers',
  listLocatie: 'odata_v1_Locaties',
  listUserAccountDayLimitView: 'odata_v1_UserAccountDayLimitViews',
} as const

export type ApiClientListFuncType =
  (typeof ApiClientListFunc)[keyof typeof ApiClientListFunc]

export const ApiClientListByValueFunc = {
  getFromInkoper: 'getFromInkoper',
} as const

export type ApiClientListByValueFuncType =
  (typeof ApiClientListByValueFunc)[keyof typeof ApiClientListByValueFunc]

export const ApiClientSearchByValueFunc = {
  searchAvailableBuyersCards: 'searchAvailableBuyerscards',
} as const

export type ApiClientSearchByValueFuncType =
  (typeof ApiClientSearchByValueFunc)[keyof typeof ApiClientSearchByValueFunc]

export const ApiClientAddFunc = {
  addBuyersCard: 'buyersCardsPOST',
  addInkoper: 'inkopersPOST',
  addInkoperLimiet: 'userAccountDayLimitsPOST',
  addInkoperPlaat: 'inkoperPlatenPOST',
} as const

export type ApiClientAddFuncType =
  (typeof ApiClientAddFunc)[keyof typeof ApiClientAddFunc]

export const ApiClientUpdateFunc = {
  updateInkoper: 'migratieInkoper',
  updateInkoperKlok: 'updateInkoperKlokken',
} as const

export type ApiClientUpdateFuncType =
  (typeof ApiClientUpdateFunc)[keyof typeof ApiClientUpdateFunc]

export const ApiClientUpdateByKeyFunc = {
  updateBuyersCard: 'buyersCardsPUT',
  updateInkoper: 'inkopersPUT',
} as const

export type ApiClientUpdateByKeyFuncType =
  (typeof ApiClientUpdateByKeyFunc)[keyof typeof ApiClientUpdateByKeyFunc]

export const ApiClientDeleteFunc = {
  deleteBuyersCard: 'buyersCardsDELETE',
  deleteInkoper: 'inkopersDELETE',
  deleteInkoperplaat: 'inkoperPlatenDELETE',
  deleteUserAccountDayLimit: 'userAccountDayLimitsDELETE',
} as const

export type ApiClientDeleteFuncType =
  (typeof ApiClientDeleteFunc)[keyof typeof ApiClientDeleteFunc]
