import { create } from 'zustand'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'

import type { GridName } from 'src/common/types'

type StoreState = {
  gridChanged: {
    buyersCards: number
    purchasers: number
    purchaserCards: number
    purchaserLimits: number
  }
  shouldPaginationBeReset: boolean
}

type Actions = {
  resetStoreState: () => void
  setGridChanged: (gridName: GridName) => void
  setShouldPaginationBeReset: (input: boolean) => void
}

const storeName = 'GridStore'

const initialStoreState: StoreState = {
  gridChanged: {
    buyersCards: 0,
    purchasers: 0,
    purchaserCards: 0,
    purchaserLimits: 0,
  },
  shouldPaginationBeReset: false,
}

const calcNextVal = (prevVal: number): number =>
  prevVal < 10 ? prevVal + 1 : 1

export const useGridStore = create<StoreState & Actions>()(
  devtools(
    persist(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (set, get: () => any) => ({
        ...initialStoreState,
        resetStoreState: () =>
          set(() => initialStoreState, false, 'resetStoreState'),
        setGridChanged: (gridName: GridName) => {
          set(
            (state: StoreState) => ({
              ...state,
              gridChanged: {
                ...state.gridChanged,
                [gridName]: calcNextVal(state.gridChanged[gridName]),
              },
            }),
            false,
            'setGridChanged'
          )
        },
        setShouldPaginationBeReset: (input: boolean) => {
          set(
            (state: StoreState) => ({
              ...state,
              shouldPaginationBeReset: input,
            }),
            false,
            'setShouldPaginationBeReset'
          )
        },
      }),
      {
        name: 'grid-model-storage',
        storage: createJSONStorage(() => sessionStorage),
      }
    ),
    {
      name: storeName,
    }
  )
)
