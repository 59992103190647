// eslint-disable-next-line
import { useTranslation } from 'react-i18next'

import { Chip, Container, Grid, Typography } from '@mui/material'

import BooleanTag from 'src/common/components/BooleanTag'
import { Config } from 'src/common/config/Config'
import { ThemeConfig } from 'src/common/config/SpacingConfig'
import { useUser } from 'src/common/providers/UserProvider'

import { OverRuling } from './components'
import {
  StyledRfhTypographyContent,
  StyledRfhTypographyTitle,
} from './ProfilePage.styles'

export default function ProfilePage(): JSX.Element {
  const HEIGHT = 190
  /*
   * Hooks
   */
  const user = useUser()
  const { t } = useTranslation()
  /*
   * Methods
   */
  const showLoginAccount = () => {
    if (user.preferredUsername && user.sub) {
      return user.preferredUsername === user.sub ? (
        user.preferredUsername
      ) : (
        <>
          {user.preferredUsername}
          <Chip color='info' label={user.sub} />
        </>
      )
    }
    return t('common.loading')
  }

  /*
   * Render
   */
  return (
    <Container maxWidth='lg' sx={{ padding: ThemeConfig.spacing.sm }}>
      <Typography
        variant={'h2'}
        sx={{
          fontSize: '34px',
          marginTop: ThemeConfig.spacing.xl,
          marginBottom: ThemeConfig.spacing.sm,
        }}
      >
        {t('common.titles.profilePage')}
      </Typography>

      <Grid
        container
        sx={{
          marginBottom: ThemeConfig.spacing.sm,
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            backgroundColor: 'white',
            padding: ThemeConfig.spacing.sm,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <StyledRfhTypographyTitle variant='h4'>
                {t('profilePage.name')}
              </StyledRfhTypographyTitle>
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledRfhTypographyContent
                data-testid='name'
                variant='subtitle1'
              >
                {user.name ? user.name : t('common.loading')}
              </StyledRfhTypographyContent>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <StyledRfhTypographyTitle variant='h4'>
                {t('profilePage.account')}
              </StyledRfhTypographyTitle>
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledRfhTypographyContent
                data-testid='account'
                variant='subtitle1'
              >
                {showLoginAccount()}
              </StyledRfhTypographyContent>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <StyledRfhTypographyTitle variant='h4'>
                {t('profilePage.email')}
              </StyledRfhTypographyTitle>
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledRfhTypographyContent
                data-testid='email'
                variant='subtitle1'
              >
                {user.email ? user.email : t('common.loading')}
              </StyledRfhTypographyContent>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        direction={'row'}
        alignItems={'stretch'}
        spacing={2}
        maxHeight={100}
        sx={{
          marginY: ThemeConfig.spacing.sm,
        }}
      >
        <Grid
          item
          xs={user.isApplicationManager && Config.env !== 'prd' ? 6 : 12}
        >
          <Grid
            container
            flexDirection={'column'}
            justifyContent={'flex-start'}
          >
            <Typography
              variant={'h2'}
              sx={{ fontSize: '34px', marginY: ThemeConfig.spacing.sm }}
            >
              {t('profilePage.permissions')}
            </Typography>
            <Grid
              item
              xs={12}
              sx={{
                backgroundColor: 'white',
                padding: ThemeConfig.spacing.sm,
                maxHeight: HEIGHT,
              }}
            >
              <Grid container flexDirection={'column'}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <StyledRfhTypographyTitle variant='h4'>
                      {t('profilePage.readOnlyRole')}
                    </StyledRfhTypographyTitle>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <StyledRfhTypographyContent
                      data-testid='readOnly'
                      variant='subtitle1'
                    >
                      <BooleanTag value={user.isReadOnly} />
                    </StyledRfhTypographyContent>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <StyledRfhTypographyTitle variant='h4'>
                      {t('profilePage.applicationManagerRole')}
                    </StyledRfhTypographyTitle>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <StyledRfhTypographyContent
                      data-testid='applicationManger'
                      variant='subtitle1'
                    >
                      <BooleanTag value={user.isApplicationManager} />
                    </StyledRfhTypographyContent>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <StyledRfhTypographyTitle variant='h4'>
                      {t('profilePage.accountManagementRole')}
                    </StyledRfhTypographyTitle>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <StyledRfhTypographyContent
                      data-testid='isAccountManagement'
                      variant='subtitle1'
                    >
                      <BooleanTag value={user.isAccountManagement} />
                    </StyledRfhTypographyContent>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <StyledRfhTypographyTitle variant='h4'>
                      {t('profilePage.contractManagementRole')}
                    </StyledRfhTypographyTitle>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <StyledRfhTypographyContent
                      data-testid='isContractBeheerder'
                      variant='subtitle1'
                    >
                      <BooleanTag value={user.isContractBeheerder} />
                    </StyledRfhTypographyContent>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {Config.env !== 'prd'
          ? user.isApplicationManager && (
              <Grid item xs={6} minHeight={500}>
                <Grid
                  container
                  flexDirection={'column'}
                  justifyContent={'flex-start'}
                >
                  <Typography
                    variant={'h2'}
                    sx={{
                      fontSize: '34px',
                      marginY: ThemeConfig.spacing.sm,
                    }}
                  >
                    {t('profilePage.overrulingTitle')}
                  </Typography>
                  <Grid item>
                    <OverRuling minHeight={HEIGHT} />
                  </Grid>
                </Grid>
              </Grid>
            )
          : null}
      </Grid>
    </Container>
  )
}
