import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Stack } from '@mui/material'

import { useStore } from 'zustand'

import { ELEMENT_HEIGHT, isDateToday, LabelDate, ThemeConfig } from 'src/common'
import { useDevToolsStore } from 'src/dev-tools'
import { FEATURES } from 'src/dev-tools/config'
import {
  isInputValid,
  useLimitsStore,
  usePurchaserLimit,
} from 'src/features/purchaserLimits'

import { usePurchaserStore } from '../../stores'
import { FormInput } from './FormInput'

export default function LimitFields(): JSX.Element {
  const { t } = useTranslation()
  const purchaser = useStore(usePurchaserStore, value => value.purchaser)
  const { TODAY, TOMORROW, NEVER } = usePurchaserLimit()
  const shouldFeatureBeEnabled = useStore(
    useDevToolsStore,
    state => state.shouldFeatureBeEnabled
  )
  const { dayLimit, isLimitAmountChanged, updateDayLimit } = useLimitsStore()

  const changeLimit = useCallback(
    (event: any) => {
      const { value } = event.target
      if (!isInputValid('inkoperLimiet', value)) return
      const resValue = value.trim() ? Number(value) : 0
      updateDayLimit({ userAccountDayLimitAmount: resValue })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const changeLimitEndDate = useCallback(
    (date: Date) => {
      if (!isInputValid('endDate', date)) return
      updateDayLimit({ endDateTime: date })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <Stack gap={ThemeConfig.spacing.s}>
      {/*InkoperLimiet */}
      <FormInput
        label={t('purchaser.dayLimitAmount')}
        name={'inkoperLimiet'}
        defaultValue={0}
        maxLength={6}
        isNumber
        value={dayLimit?.userAccountDayLimitAmount ?? 0}
        disabled={
          !purchaser?.inkoperID ||
          purchaser?.inkoperID === 0 ||
          !shouldFeatureBeEnabled(FEATURES.INKOPERLIMIETEN) ||
          isDateToday(dayLimit?.endDateTime)
        }
        onChange={changeLimit}
      />
      {/* Enddate */}
      <LabelDate
        fieldName='limietEindDatum'
        defaultValue={NEVER}
        value={new Date(dayLimit?.endDateTime)}
        label={t('purchaser.dayLimitEndDateTime')}
        flowDirection='row'
        minDate={
          Number(dayLimit?.userAccountDayLimitAmount) > 0 &&
          !isLimitAmountChanged()
            ? TODAY
            : TOMORROW
        }
        disabled={
          !purchaser?.inkoperID ||
          purchaser?.inkoperID === 0 ||
          !shouldFeatureBeEnabled(FEATURES.INKOPERLIMIETEN)
        }
        onChange={changeLimitEndDate}
        sx={{
          fontSize: '1em',
          pr: 0.5,
          maxHeight: ELEMENT_HEIGHT,
        }}
      />
    </Stack>
  )
}
