import type { ReactNode } from 'react'

import {
  createTheme,
  CssBaseline,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material'
import rfhTheme from '@rfh-core/theme'
import '@rfh-core/theme/dist/fonts.css'

const theme = createTheme(rfhTheme)
export const ThemeProvider = ({
  children,
}: {
  children: ReactNode
}): JSX.Element => (
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    {children}
  </MuiThemeProvider>
)
