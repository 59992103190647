import { Route, Switch } from 'react-router-dom'

import { useStore } from 'zustand'

import { FEATURES, useDevToolsStore } from 'src/dev-tools'
import {
  BuyersCards,
  ManageBuyersCards,
  ManagePurchaser,
  PurchaserLimits,
  Purchasers,
} from 'src/features'
import { BuyersCardsHistory, HomePage, NotFound, ProfilePage } from 'src/views'

import {
  AccountManagementRoute,
  ContractbeheerderRoute,
  SecureRoute,
} from '../guards'

export default function InternalRoutes(): JSX.Element {
  const shouldFeatureBeEnabled = useStore(
    useDevToolsStore,
    state => state.shouldFeatureBeEnabled
  )

  // Example of route enabled by a feature toggle
  // const { features } = useDevToolsStore()

  // <ConsulterRoute
  //   path='/dashboard/example-feature'
  //   exact
  //   component={ExampleFeature}
  //   visible={features?.[FEATURES.EXAMPLE]}
  // />

  return (
    <Switch>
      <SecureRoute path='/dashboard' exact component={HomePage} />
      <SecureRoute
        path='/dashboard/buyerscard/:cardId'
        exact
        component={BuyersCards}
      />
      <AccountManagementRoute
        path='/dashboard/buyerscards/add'
        exact
        component={ManageBuyersCards}
      />
      <AccountManagementRoute
        path='/dashboard/buyerscards/:cardId/edit'
        exact
        component={ManageBuyersCards}
      />
      <SecureRoute path='/dashboard/buyerscards' component={BuyersCards} />
      <SecureRoute
        path='/dashboard/buyerscards-history'
        component={BuyersCardsHistory}
      />
      <SecureRoute path='/dashboard/purchasers' exact component={Purchasers} />
      <ContractbeheerderRoute
        path='/dashboard/purchasers/add'
        exact
        component={ManagePurchaser}
      />
      <ContractbeheerderRoute
        path='/dashboard/purchasers/:id'
        exact
        component={ManagePurchaser}
      />
      <SecureRoute
        path='/dashboard/purchaser-limits'
        exact
        component={PurchaserLimits}
        visible={shouldFeatureBeEnabled(FEATURES.INKOPERLIMIETEN)}
      />
      {/* SETTINGS */}
      <SecureRoute path='/dashboard/profile' component={ProfilePage} />
      <Route component={NotFound} />
    </Switch>
  )
}
