import { utcToZonedTime } from 'date-fns-tz'

import { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'

import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material'
import { CheckIcon } from '@rfh-core/icons'

import CustomerInput from 'src/common/components/CustomerInput'
import { Divider } from 'src/common/components/Divider'
import { ThemeConfig } from 'src/common/config/SpacingConfig'
import {
  ApiClientGetFilter,
  Entity,
  useAddEntity,
  useFetchOne,
  usePathname,
  useUpdateEntityByKey,
} from 'src/common/hooks'
import { LabelCheckbox, LabelDate, useUserStore } from 'src/common/index'
import { IBuyersCard, IBuyersCardView } from 'src/common/services/client'
import { getUTCDate, snackbarUtils } from 'src/common/utils'

import { BuyersCardsDialogAvailablePlates } from '../components/BuyersCardsDialogAvailablePlates'
import { defaultBuyerscard } from '../constants'
import { useBuyersCardStore } from '../stores/BuyersCardStore'

export default function ManageBuyersCards(): JSX.Element {
  const { t } = useTranslation()
  const { user } = useUserStore()
  const { ADDING, urlParam } = usePathname('buyerscards')
  const {
    buyersCard,
    oldBuyersCard,
    setBuyersCard,
    setOldBuyersCard,
    updateBuyersCard,
    resetBuyersCardState,
  } = useBuyersCardStore()

  const buyerscardId = urlParam
  const filter: ApiClientGetFilter = {
    key: buyerscardId,
  }

  const { data: fetchedBuyerscard } = useFetchOne<IBuyersCardView>(
    filter,
    Entity.BuyersCard,
    Boolean(buyerscardId)
  )

  /*
  State
  */
  const [isValidCustomerNumber, setIsValidCustomerNumber] = useState(false)
  const [newCard, setNewCard] = useState(false)

  /*
  Hooks
  */
  const history = useHistory()

  const addEntity = useAddEntity<IBuyersCard>(Entity.BuyersCard)
  const updateEntity = useUpdateEntityByKey<IBuyersCard>(Entity.BuyersCard)

  const handleInputChangeNumber = (evt: any): void => {
    const { name, value } = evt.target
    updateBuyersCard({ [name]: value })
  }

  const handleKlantNummerChange = (value: number): void => {
    setIsValidCustomerNumber(Boolean(value))
    updateBuyersCard({ customerRfhRelationId: value })
  }

  const handleCheckedChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { name, checked } = event.target
    updateBuyersCard({ [name]: checked })
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target
    updateBuyersCard({ [name]: value })
  }

  const handleDateChange = (date: Date | null): void => {
    updateBuyersCard({
      endDate: date,
    })
  }

  const addBuyersCard = async () => {
    if (buyersCard.startDate > buyersCard.endDate) {
      snackbarUtils.error(t('buyersCards.addBuyersCards.invalidDate'))
      return
    }
    if (addEntity.isLoading || updateEntity.isLoading) {
      return Promise.resolve()
    }

    try {
      const body: IBuyersCard = {
        ...buyersCard,
        creationDateTime: ADDING
          ? getUTCDate(new Date())
          : oldBuyersCard?.creationDateTime,
        creationUser: ADDING ? user.sub : oldBuyersCard?.creationUser,
        mutationDateTime: getUTCDate(new Date()),
        mutationUser: user.sub,
      }
      let result
      if (ADDING) {
        result = await addEntity.mutateAsync(body)
      } else {
        await updateEntity.mutateAsync({ key: urlParam, body })
        result = buyersCard
      }

      if (result?.buyersCardId) {
        setNewCard(true)
        setBuyersCard(result)
        setOldBuyersCard(result)
        history.replace(`/dashboard/buyerscards/${result?.buyersCardId}/edit`)
      }
    } catch (error) {
      snackbarUtils.error(`${error}`)
    }
  }

  /**
   * useEffects
   */
  useEffect(() => {
    if (ADDING) {
      setBuyersCard(defaultBuyerscard)
      setOldBuyersCard(defaultBuyerscard)
    } else {
      if (fetchedBuyerscard?.isVervallen) {
        history.replace('/dashboard/buyerscards')
      }
      setOldBuyersCard(fetchedBuyerscard)
      setBuyersCard(fetchedBuyerscard)
    }
    return () => {
      resetBuyersCardState()
    }
  }, [
    ADDING,
    urlParam,
    setBuyersCard,
    setOldBuyersCard,
    fetchedBuyerscard,
    resetBuyersCardState,
    history,
  ])

  /*
  Render
  */
  return (
    <Container maxWidth='xl' sx={{ padding: ThemeConfig.spacing.sm }}>
      <Grid
        container
        spacing={2}
        sx={{
          width: '100%',
          margin: '0',
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            padding: '0 !important',
          }}
        >
          <Typography
            variant={'h1'}
            style={{
              fontSize: '35px',
              margin: `${ThemeConfig.spacing.sm * 8} 0`,
            }}
          >
            {ADDING
              ? t('common.titles.addBuyersCards')
              : t('common.titles.buyersCards')}
          </Typography>
        </Grid>
        {newCard ? (
          <Grid
            item
            xs={12}
            sx={{
              padding: '16px 0px !important',
            }}
          >
            <Alert
              color='success'
              icon={<CheckIcon />}
              onClose={() => setNewCard(false)}
              title={t('buyersCards.addBuyersCards.addedBuyersCards')}
            >
              <Link to={`/dashboard/buyerscard/${oldBuyersCard?.buyersCardId}`}>
                {t('common.clickHere')}
              </Link>{' '}
              {t('buyersCards.addBuyersCards.plateNumberAdded', {
                code: oldBuyersCard?.buyersCardId,
              })}
            </Alert>
          </Grid>
        ) : null}
        <Box
          component='form'
          onSubmit={addBuyersCard}
          sx={{
            width: '100%',
          }}
        >
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <CustomerInput
                sx={{
                  mt: ThemeConfig.spacing.s,
                  mb: ThemeConfig.spacing.l,
                }}
                showOnlyAvailableBuyers={true}
                changeCustomerNumber={handleKlantNummerChange}
                changeValid={setIsValidCustomerNumber}
                value={buyersCard?.customerRfhRelationId}
                disabled={!ADDING}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                fullWidth
                label={t('buyersCards.plateNumber')}
                id='buyersCardId'
                name='buyersCardId'
                type='number'
                placeholder={t('buyersCards.plateNumber')}
                value={buyersCard?.buyersCardId}
                disabled={!ADDING}
                onChange={handleInputChangeNumber}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <BuyersCardsDialogAvailablePlates
                        onChangePlate={handleInputChangeNumber}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              sm={6}
              xs={12}
              sx={{ display: 'flex', flexDirection: 'row', mx: 0, px: 0 }}
            >
              <LabelCheckbox
                label='aalsmeer'
                fieldName='aalsmeer'
                flowDirection='column'
                value={buyersCard?.aalsmeer}
                onChange={handleCheckedChange}
              />
              <LabelCheckbox
                label='naaldwijk'
                fieldName='naaldwijk'
                flowDirection='column'
                value={buyersCard?.naaldwijk}
                onChange={handleCheckedChange}
              />
              <LabelCheckbox
                label='rijnsburg'
                fieldName='rijnsburg'
                flowDirection='column'
                value={buyersCard?.rijnsburg}
                onChange={handleCheckedChange}
              />
              <LabelCheckbox
                label='eelde'
                fieldName='eelde'
                flowDirection='column'
                value={buyersCard?.eelde}
                onChange={handleCheckedChange}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <LabelDate
                fieldName='startDate'
                value={utcToZonedTime(
                  new Date(
                    buyersCard?.startDate ?? defaultBuyerscard.startDate
                  ),
                  'CET'
                )}
                label={t('buyersCards.startDate')}
                flowDirection='column'
                disabled
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <LabelDate
                fieldName='endDate'
                value={utcToZonedTime(
                  new Date(buyersCard?.endDate ?? defaultBuyerscard.endDate),
                  'CET'
                )}
                label={t('buyersCards.endDate')}
                onChange={handleDateChange}
                flowDirection='column'
                disabled={ADDING}
              />
            </Grid>
            {!ADDING && (
              <>
                <Divider {...{ marginTop: 2, width: '100%' }} />
                <Grid item sm={6} xs={12}>
                  <LabelCheckbox
                    label={t('buyersCards.blocked')}
                    fieldName='blockedindication'
                    value={buyersCard?.blockedindication}
                    flowDirection='row'
                    onChange={handleCheckedChange}
                    sx={{ fontWeight: 'bold' }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    name='remark'
                    label={t('common.remark')}
                    value={buyersCard?.remark}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Grid>
              </>
            )}
            <Divider
              {...{
                marginTop: 2,
                width: '100%',
              }}
            />
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: ThemeConfig.spacing.s,
                marginBottom: ThemeConfig.spacing.sm,
              }}
            >
              <Button
                variant='outlined'
                sx={{
                  marginRight: ThemeConfig.spacing.s,
                }}
                onClick={() => history.goBack()}
              >
                {t('common.back')}
              </Button>
              <Button
                variant='contained'
                disabled={!isValidCustomerNumber || !buyersCard?.buyersCardId}
                onClick={addBuyersCard}
              >
                {ADDING ? t('common.add') : t('common.save')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Container>
  )
}
