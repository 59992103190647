import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Container, Grid, Typography } from '@mui/material'

import { useStore } from 'zustand'

import {
  allGridsDefaults,
  Divider,
  Entity,
  getCurrentPaginationModel,
  getCurrentSortModel,
  getOrderBy,
  getSkip,
  getTop,
  type IInkoperView,
  ThemeConfig,
  useFetchMany,
  useGridStore,
  useUserStore,
} from 'src/common'

import { PurchasersFilter, PurchasersGrid } from '../components'
import { useCardRowsStore, usePurchasersFilterStore } from '../stores'
import { usePurchaserStore } from '../stores/PurchaserStore'

export default function Purchasers(): JSX.Element {
  const { CACHE_KEY } = allGridsDefaults.purchasers
  const { t } = useTranslation()
  const { user } = useUserStore()
  const {
    isPurchasersFilterInitialized,
    mustRefreshData,
    purchasersFilter,
    setMustRefreshData,
  } = usePurchasersFilterStore()
  const { resetPurchaserState, setUserId } = usePurchaserStore()
  const resetCardRowsState = useStore(
    useCardRowsStore,
    state => state.resetCardRowsState
  )
  const { setGridChanged, setShouldPaginationBeReset } = useGridStore()

  const refresh = useCallback(
    () => setMustRefreshData(true),
    [setMustRefreshData]
  )

  const resetGridState = useCallback(() => {
    refresh()
    setShouldPaginationBeReset(true)
    setGridChanged('purchasers')
  }, [refresh, setGridChanged, setShouldPaginationBeReset])

  const {
    data: result,
    isLoading,
    refetch,
  } = useFetchMany<IInkoperView>(
    {
      top: getTop(getCurrentPaginationModel(CACHE_KEY)),
      skip: getSkip(getCurrentPaginationModel(CACHE_KEY)),
      count: true,
      orderby: getOrderBy(getCurrentSortModel(CACHE_KEY)),
      filter: purchasersFilter?.length > 0 ? purchasersFilter : undefined,
      expand: 'InkoperPlaten',
    },
    Entity.IInkoperView,
    isPurchasersFilterInitialized
  )

  useEffect(() => {
    resetGridState()
    resetPurchaserState()
    resetCardRowsState()
    setUserId(user.sub)
    refresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isPurchasersFilterInitialized) {
      return
    } else if (mustRefreshData) {
      refetch<IInkoperView>().then(() => {
        setMustRefreshData(false)
      })
    }
  }, [
    isPurchasersFilterInitialized,
    mustRefreshData,
    refetch,
    setMustRefreshData,
  ])

  return (
    <Container sx={{ maxWidth: '1920px !important' }}>
      <Grid
        container
        sx={{
          width: '100%',
        }}
      >
        <Grid item xs={12}>
          <Typography
            variant='h1'
            sx={{
              fontSize: '2.5rem',
              my: `${ThemeConfig.spacing.sm * 8}px`,
            }}
          >
            {user.isContractBeheerder || user.isApplicationManager
              ? t('common.titles.purchasers')
              : t('common.titles.purchasersReadOnly')}
          </Typography>
        </Grid>
        <Grid item sm={12} mt={2}>
          <PurchasersFilter resetFilter={resetGridState} />
        </Grid>
        <Divider {...{ marginTop: 2, width: '100%' }} />
        <Grid item sm={12}>
          <PurchasersGrid
            isLoading={isLoading}
            refresh={refresh}
            rows={result?.records ?? []}
            totalCount={result?.count ?? 0}
          />
        </Grid>
      </Grid>
    </Container>
  )
}
