import {
  ApiClientAddFunc,
  ApiClientDeleteFunc,
  ApiClientGetFunc,
  ApiClientListByValueFunc,
  ApiClientListFunc,
  ApiClientSearchByValueFunc,
  ApiClientUpdateByKeyFunc,
  ApiClientUpdateFunc,
  Entity,
  EntityType,
  FuncSort,
  FuncSortType,
} from './lib.resources'
import { FuncType } from './types'

export const getFuncByEntity = (
  funcSort: FuncSortType,
  entity: EntityType
): FuncType => {
  let func: FuncType

  switch (funcSort) {
    case FuncSort.get:
      switch (entity) {
        case Entity.IInkoperPlaat:
          func = ApiClientGetFunc.getInkoperPlaat
          break
        case Entity.IInkoperView:
          func = ApiClientGetFunc.getInkoperView
          break
        case Entity.IKoper:
          func = ApiClientGetFunc.getKoper
          break
        case Entity.BuyersCard:
          func = ApiClientGetFunc.getBuyersCard
          break
        case Entity.BuyersCardView:
          func = ApiClientGetFunc.getBuyersCardView
          break
        default:
          func = undefined
          break
      }
      break
    case FuncSort.list:
      switch (entity) {
        case Entity.IInkoperKlokView:
          func = ApiClientListByValueFunc.getFromInkoper
          break
        case Entity.IInkoperView:
          func = ApiClientListFunc.listInkoperView
          break
        case Entity.IKoper:
          func = ApiClientListFunc.listKoper
          break
        case Entity.ILocatie:
          func = ApiClientListFunc.listLocatie
          break
        case Entity.IInkoperPlaat:
          func = ApiClientListFunc.listInkoperPlaat
          break
        case Entity.IUserAccountDayLimitView:
          func = ApiClientListFunc.listUserAccountDayLimitView
          break
        case Entity.FloridayUser:
          func = ApiClientListFunc.listFloridayUser
          break
        case Entity.BuyersCard:
          func = ApiClientListFunc.listBuyersCard
          break
        case Entity.BuyersCardView:
          func = ApiClientListFunc.listBuyersCardView
          break
        default:
          func = undefined
          break
      }
      break
    case FuncSort.search:
      if (entity === Entity.BuyersCard) {
        func = ApiClientSearchByValueFunc.searchAvailableBuyersCards
      } else {
        func = undefined
      }
      break
    case FuncSort.add:
      switch (entity) {
        case Entity.BuyersCard:
          func = ApiClientAddFunc.addBuyersCard
          break
        case Entity.IInkoperPlaat:
          func = ApiClientAddFunc.addInkoperPlaat
          break
        case Entity.IInkoper:
          func = ApiClientAddFunc.addInkoper
          break
        case Entity.IUserAccountDayLimit:
          func = ApiClientAddFunc.addInkoperLimiet
          break
        default:
          func = undefined
          break
      }
      break
    case FuncSort.update:
      switch (entity) {
        case Entity.BuyersCard:
          func = ApiClientUpdateByKeyFunc.updateBuyersCard
          break
        case Entity.IInkoper:
          func = ApiClientUpdateByKeyFunc.updateInkoper
          break
        case Entity.IInkoperKlokView:
          func = ApiClientUpdateFunc.updateInkoperKlok
          break
        case Entity.IInkoperView:
          func = ApiClientUpdateFunc.updateInkoper
          break
        default:
          func = undefined
          break
      }
      break
    case FuncSort.delete:
      switch (entity) {
        case Entity.BuyersCard:
          func = ApiClientDeleteFunc.deleteBuyersCard
          break
        case Entity.IInkoper:
          func = ApiClientDeleteFunc.deleteInkoper
          break
        case Entity.IInkoperPlaat:
          func = ApiClientDeleteFunc.deleteInkoperplaat
          break
        case Entity.IUserAccountDayLimit:
          func = ApiClientDeleteFunc.deleteUserAccountDayLimit
          break
        default:
          func = undefined
          break
      }
      break
  }

  return func
}
