import {
  ChangeEvent,
  FC,
  FocusEvent,
  type ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react'

import { FormControl, FormLabel, Grid, lighten, TextField } from '@mui/material'

import { ELEMENT_HEIGHT, noOp } from 'src/common'
import { useDebouncedInput } from 'src/common/hooks'

type FormInputProps = {
  label?: string
  name?: string
  value?: string | number
  defaultValue?: string | number
  disabled?: boolean
  maxLength?: number
  isNumber?: boolean
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  sx?: Record<string, unknown>
  children?: ReactNode
}

export const FormInput: FC<FormInputProps> = ({
  label = '',
  name = '',
  defaultValue = '',
  value = '',
  disabled = false,
  maxLength = 0,
  isNumber = false,
  onBlur = noOp,
  onChange = noOp,
  sx,
  children = null,
}) => {
  const { debounced } = useDebouncedInput()
  const [inputValue, setInputValue] = useState<string | number>(
    defaultValue || ''
  )

  const getTrimmedValue = useCallback(
    (newValue: string | number) => {
      if (isNumber) {
        newValue = String(newValue).replace(/\D/g, '')
      }
      return maxLength > 0 ? String(newValue).slice(0, maxLength) : newValue
    },
    [isNumber, maxLength]
  )

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value
    setInputValue(getTrimmedValue(newValue))
    debounced(onChange)(event)
  }

  useEffect(() => {
    setInputValue(getTrimmedValue(value))
  }, [getTrimmedValue, maxLength, value])

  return (
    <Grid container flexDirection={'row'} alignItems={'center'} sx={sx}>
      <Grid item xs={12} sm={5}>
        <FormControl sx={{ ml: 0 }}>
          <FormLabel
            htmlFor={`id-${name}`}
            sx={{
              color: theme => lighten(theme.rfhColors.black, 0.2),
              fontSize: '1em',
              pr: 0.5,
              maxHeight: ELEMENT_HEIGHT,
            }}
          >
            {label}
          </FormLabel>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={7} sx={{ maxHeight: ELEMENT_HEIGHT }}>
        {children ?? (
          <TextField
            id={`id-${name}`}
            name={name}
            value={inputValue}
            disabled={disabled}
            fullWidth
            onBlur={onBlur}
            onChange={handleChange}
            sx={{ fontSize: '1em' }}
          />
        )}
      </Grid>
    </Grid>
  )
}
