/* eslint-disable @typescript-eslint/unbound-method */
import { FC, ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useLocation } from 'react-router-dom'

import {
  Box,
  Button,
  Collapse,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  AppBar as MuiAppBar,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material'
import {
  ArrowDownIcon,
  BackgroundFlowerIcon,
  GlobeFilledIcon,
  GridViewIcon,
  InfoIcon,
  LogoutIcon,
  MenuIcon,
  UserIcon,
} from '@rfh-core/icons'
import { RfhLogo } from '@rfh-core/illustrations'

import { useStore } from 'zustand'

import { useAuthentication } from 'src/common/providers/AuthenticationProvider'
import { useUserStore } from 'src/common/stores/UserStore'
import { useDevToolsStore } from 'src/dev-tools'
import { fallbackLng, locales, supportedLanguages } from 'src/i18n/index'

import { FEATURES } from '../../../dev-tools/config/index'
import FlagDE from './icons/FlagDE'
import FlagGB from './icons/FlagGB'
import FlagNL from './icons/FlagNL'

type MenuItemType = {
  onClick?: () => void
  text: string
  icon?: ReactElement
}
function CustomListItem({ item }: Readonly<{ item: MenuItemType }>) {
  const props = { onClick: item.onClick }
  return (
    <ListItem disablePadding>
      <ListItemButton {...props}>
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText primary={item.text} />
      </ListItemButton>
    </ListItem>
  )
}

const AppBar: FC = (): JSX.Element => {
  const { user } = useUserStore()
  const { login, logout } = useAuthentication()
  const { i18n, t } = useTranslation()
  const [menuOpen, setMenuOpen] = useState(false)
  const [openLang, setOpenLang] = useState(false)
  const history = useHistory()
  const theme = useTheme()
  const shouldFeatureBeEnabled = useStore(
    useDevToolsStore,
    state => state.shouldFeatureBeEnabled
  )

  // This is needed to change the language to the users language from another domain
  const location = useLocation()
  const urlPart = new URLSearchParams(location.search).get('language')
  const queryStringLanguage: string = urlPart
    ? urlPart.toLowerCase().slice(0, 2)
    : fallbackLng

  useEffect(() => {
    if (locales.map(l => l.toString()).includes(queryStringLanguage)) {
      i18n.changeLanguage(queryStringLanguage)
    } else if (urlPart && supportedLanguages.includes(urlPart)) {
      i18n.changeLanguage(urlPart)
    } else if (queryStringLanguage) {
      console.warn(
        `Could not detect language from url, queryStringLanguage: ${queryStringLanguage}`
      )
    }
  }, [queryStringLanguage, i18n, urlPart])

  const onClickRedirectHandler = (path: string) => (): void => {
    setMenuOpen(false)
    history.push(path)
  }

  const getLanguages = [
    {
      value: 'de',
      label: t('appBar.languageNameDE'),
      icon: <FlagDE />,
    },
    {
      value: 'en',
      label: t('appBar.languageNameEN'),
      icon: <FlagGB />,
    },
    {
      value: 'nl',
      label: t('appBar.languageNameNL'),
      icon: <FlagNL />,
    },
  ] as const

  const getPrimaryMenuItems = (): MenuItemType[] => {
    const menu: MenuItemType[] = []

    menu.push({
      onClick: onClickRedirectHandler('/dashboard'),
      text: t('common.titles.homePage'),
      icon: <BackgroundFlowerIcon />,
    })
    if (user.isAuthenticated) {
      menu.push({
        onClick: onClickRedirectHandler('/dashboard/buyerscards'),
        text: user.isAccountManagement
          ? t('common.titles.buyersCards')
          : t('common.titles.buyersCardsReadOnly'),
        icon: <GridViewIcon />,
      })
      menu.push({
        onClick: onClickRedirectHandler('/dashboard/purchasers'),
        text: user.isContractBeheerder
          ? t('common.titles.purchasers')
          : t('common.titles.purchasersReadOnly'),
        icon: <GridViewIcon />,
      })
      if (shouldFeatureBeEnabled(FEATURES.INKOPERLIMIETEN)) {
        menu.push({
          onClick: onClickRedirectHandler('/dashboard/purchaser-limits'),
          text: t('common.titles.purchaserLimitsReadOnly'),
          icon: <GridViewIcon />,
        })
      }
      menu.push({
        onClick: onClickRedirectHandler('/dashboard/buyerscards-history'),
        text: t('common.titles.buyersCardsHistory'),
        icon: <InfoIcon />,
      })
    }

    return menu
  }

  const getSecondaryMenuItems = () => {
    const menu: MenuItemType[] = []
    if (user.isAuthenticated) {
      menu.push({
        onClick: onClickRedirectHandler('/dashboard/profile'),
        text: t('common.titles.profilePage'),
        icon: <UserIcon />,
      })
      menu.push({
        onClick: () => logout(),
        text: t('common.titles.logout'),
        icon: <LogoutIcon />,
      })
    } else {
      menu.push({
        onClick: () => login(),
        text: t('common.titles.login'),
        icon: <LogoutIcon />,
      })
    }
    return menu
  }

  /*
   * Render
   */
  return (
    <>
      <MuiAppBar elevation={2} position='sticky' color='inherit'>
        <Toolbar disableGutters variant='dense' sx={{ m: theme.spacing(0, 4) }}>
          <Button
            component={Link}
            to='/dashboard'
            sx={{
              p: 0,
              m: 0,
              color: 'common.black',
              '&:focus': { outline: 'none' },
            }}
            variant='text'
          >
            <RfhLogo sx={{ width: '85px', height: 'inherit' }} />
          </Button>
          <Box
            zIndex={-1}
            justifyContent='center'
            textAlign='center'
            display='flex'
          >
            <Typography
              variant='h1'
              sx={{
                [theme.breakpoints.up('xs')]: {
                  fontSize: theme.typography.pxToRem(14),
                  textTransform: 'uppercase',
                },
              }}
            >
              {t('common.applicationName')}
            </Typography>
          </Box>
          <IconButton
            aria-label={'open menu'}
            onClick={() => {
              setMenuOpen(!menuOpen)
            }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </MuiAppBar>
      <Drawer
        anchor='right'
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        PaperProps={{ sx: { minWidth: '400px' } }}
      >
        <List>
          {getPrimaryMenuItems().map(item => (
            <CustomListItem key={item.text} item={item} />
          ))}
        </List>
        <Divider />
        <List>
          {getSecondaryMenuItems().map(item => (
            <CustomListItem key={item.text} item={item} />
          ))}
          <ListItem disablePadding>
            <ListItemButton onClick={() => setOpenLang(!openLang)}>
              <ListItemIcon>
                <GlobeFilledIcon />
              </ListItemIcon>
              <ListItemText primary={t('appBar.changeLanguage')} />
            </ListItemButton>
            <ArrowDownIcon
              sx={{
                position: 'absolute',
                right: theme.spacing(3),
                fontSize: theme.typography.fontSize,
                transform: openLang ? 'rotate(0deg)' : 'rotate(270deg)',
              }}
            />
          </ListItem>
        </List>
        <Collapse in={openLang}>
          <List>
            {getLanguages.map(lang => (
              <ListItem key={lang.value} disablePadding>
                <ListItemButton onClick={() => i18n.changeLanguage(lang.value)}>
                  <ListItemIcon>{lang.icon}</ListItemIcon>
                  <ListItemText>{lang.label}</ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Collapse>
      </Drawer>
    </>
  )
}

export default AppBar
