import { type ReactNode } from 'react'

import { Typography } from '@mui/material'
import Grid from '@mui/material/Grid'

import Spinner from 'src/common/components/Spinner'
import { ThemeConfig } from 'src/common/index'

import {
  StyledBoxLoader,
  StyledGridCard,
  StyledGridSmall,
  StyledRfhTypographyText,
} from './BuyersCardsDetails.styles'

type detailProp = {
  name: string
  value: ReactNode
}
type Props = {
  isLoading: boolean
  isError: boolean
  title: string
  details: detailProp[]
}

const DetailCard = ({
  isLoading,
  isError,
  title,
  details,
}: Props): JSX.Element => (
  <StyledGridCard item lg={6} xs={12}>
    <Grid
      item
      xs={12}
      sx={{
        padding: '0 !important',
        my: ThemeConfig.spacing.sm,
      }}
    >
      <Typography variant={'h2'} style={{ fontSize: '34px' }}>
        {title}
      </Typography>
    </Grid>
    <StyledBoxLoader isCard={!isLoading}>
      {!isLoading ? (
        details.map(detail => (
          <StyledGridSmall container key={detail.name}>
            <Grid item xs={12} sm={6}>
              <Typography variant={'subtitle1'}>{detail.name}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledRfhTypographyText variant={'body2'}>
                {detail.value}
              </StyledRfhTypographyText>
            </Grid>
          </StyledGridSmall>
        ))
      ) : (
        <Spinner isError={isError} />
      )}
    </StyledBoxLoader>
  </StyledGridCard>
)

export default DetailCard
