import type { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium'
import { AlertOIcon } from '@rfh-core/icons'

import {
  formatDate,
  formatNumber,
  type ILimitsFilterInput,
  type IUserAccountDayLimitView,
  utcNever,
} from 'src/common'
import i18n from 'src/i18n'

export const defaultDayLimit = {
  userAccountDayLimitId: 0,
  userAccountDayLimitAmount: 0,
  endDateTime: utcNever(),
  userAccountName: '',
}

export const defaultFilter: ILimitsFilterInput = {
  userAccountName: undefined,
  customerAdministrationNumber: undefined,
  customerName: undefined,
}

export const ROOT_PATH = 'purchaser-limits'
export const purchaserLimitsColumns: GridColDef[] = [
  {
    field: 'userAccountName',
    headerName: i18n.t('purchaser.purchaserIdentification'),
    headerAlign: 'center',
    align: 'center',
    flex: 1,
  },
  {
    field: 'customerAdministrationNumber',
    headerName: i18n.t('purchaser.buyerNumber'),
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    valueFormatter: row => formatNumber(row),
  },
  {
    field: 'customerName',
    headerName: i18n.t('purchaser.buyerName'),
    headerAlign: 'left',
    align: 'left',
    flex: 3,
  },
  {
    field: 'userAccountDayLimitAmount',
    headerName: i18n.t('purchaser.dayLimitAmount'),
    headerAlign: 'center',
    align: 'center',
    flex: 1,
  },
  {
    field: 'startDateTime',
    headerName: i18n.t('purchaser.dayLimitStartDateTime'),
    headerAlign: 'center',
    type: 'date',
    align: 'center',
    flex: 1,
    valueFormatter: row => formatDate(row, i18n.t('common.dateFormatShort')),
  },
  {
    field: 'endDateTime',
    headerName: i18n.t('purchaser.dayLimitEndDateTime'),
    headerAlign: 'center',
    type: 'date',
    align: 'center',
    flex: 1,
    valueFormatter: row => formatDate(row, i18n.t('common.dateFormatShort')),
  },
  {
    field: 'isUserAccountBlocked',
    headerName: i18n.t('purchaser.blocked'),
    headerAlign: 'center',
    align: 'center',
    type: 'boolean',
    flex: 0.8,
    valueFormatter: ({ value }) => value, // Needed for csv exports to supply the boolean as `Yes` and `No`, it uses the value from the valueGetter
    valueGetter: ({ value }) =>
      value ? i18n.t('common.yes') : i18n.t('common.no'), // Needed for excel exports to supply the boolean as `Yes` and `No`
    renderCell: (params: GridRenderCellParams) =>
      params.row.isUserAccountBlocked ? (
        <AlertOIcon
          sx={{
            color: theme => `${theme.rfhColors.alertRed}`,
          }}
        />
      ) : (
        '-'
      ),
  },
  {
    field: 'blockedDate',
    headerName: i18n.t('purchaser.blockingDateTime'),
    headerAlign: 'center',
    type: 'date',
    align: 'center',
    flex: 1.5,
    valueFormatter: row => formatDate(row, i18n.t('common.dateFormatShort')),
  },
]

export const dummyRows: IUserAccountDayLimitView[] = [
  // fill dummy values
  {
    userAccountDayLimitId: 1,
    userAccountId: 1,
    userAccountName: 'dummy',
    customerAdministrationNumber: 1,
    customerName: 'dummy',
    userAccountDayLimitAmount: 20000,
    startDateTime: new Date(),
    endDateTime: new Date(),
    isUserAccountBlocked: true,
    blockedDate: new Date(),
    creationDateTime: new Date(),
    creationUser: 'dummy',
    mutationDateTime: new Date(),
    mutationUser: 'dummy',
  },
]
