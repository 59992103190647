import {
  gridClasses,
  GridPaginationModel,
  GridSortModel,
} from '@mui/x-data-grid-premium'

import { ThemeConfig } from '../config'
import { PageSize, PageSizeType } from '../lib'
import { GridDefaults, GridName } from '../types'

export const MAX_NUMBER_COLS_SORTED = 5 as const
export const DEFAULT_PAGE_SIZE = 10 as const
export const NO_DATA_ROWS = 1 as const
export const BUTTONS_GAP = 0.5 as const
export const ADD_PATH = 'add'
export const GRID_MARGIN_TOP = ThemeConfig.spacing.xs * 4
export const ELEMENT_HEIGHT = ThemeConfig.spacing.xl * 4.9
export const rowsPerPageOptions: PageSizeType[] = [
  PageSize.sm,
  PageSize.md,
  PageSize.lg,
  PageSize.xl,
]
export const HttpCode = {
  OK: 200,
  CREATED: 201,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
}
export const MAX_VALID_VALUE = 99999990 as const
export const Location = Object.freeze({
  aalsmeer: 1,
  naaldwijk: 2,
  rijnsburg: 3,
  eelde: 7,
})
export const gridHeaderStyles = {
  [`& .${gridClasses.columnHeader}`]: {
    maxHeight: '6rem !important',
  },
  [`& .${gridClasses.columnHeadersInner}`]: {
    paddingBottom: '0.5rem',
    paddingTop: '0.25rem',
  },
  [`& .${gridClasses.columnHeader}:last-child .${gridClasses.columnSeparator}`]:
    { display: 'none' },
  [`& .${gridClasses.columnHeaderTitleContainer}`]: {
    flexDirection: 'row',
  },
  [`& .${gridClasses.columnHeaderTitle}`]: {
    paddingBottom: '0.25rem',
    lineHeight: 1.2,
    textAlign: 'center',
    textOverflow: 'clip',
    whiteSpace: 'break-spaces',
  },
  [`& .${gridClasses.toolbarContainer}`]: {
    gap: 0,
  },
}
export const defaultPaginationModel: GridPaginationModel = {
  page: 0,
  pageSize: PageSize.sm,
}
export const defaultSortModel: GridSortModel = []
export const allGridsDefaults: Record<GridName, GridDefaults> = {
  buyersCards: {
    CACHE_KEY: 'buyers-cards-datagrid' as const,
    initialState: {
      columns: {
        columnVisibilityModel: {
          customerRfhRelationId: true,
          customrRfhRelationName: true,
          buyersCardId: true,
          aalsmeer: true,
          naaldwijk: true,
          rijnsburg: true,
          eelde: true,
          startDate: false,
          endDate: false,
          releasedDateTime: false,
          blockedDateTime: false,
          isVervallen: true,
          blockedindication: true,
          remark: true,
          bewerken: true,
          delete: true,
          __check__: true,
        },
      },
    },
  },
  purchasers: {
    CACHE_KEY: 'purchasers-datagrid' as const,
    initialState: {
      columns: {
        columnVisibilityModel: {
          inkoperIdentificatie: true,
          koperNummer: true,
          koperNaam: true,
          blokkeerIndicatie: true,
          blokkeerRedenOmschrijving: true,
          locatieOmschrijving: true,
          mutatieGebruiker: false,
          mutatieDatumTijd: false,
          vervallenDatumTijd: false,
          zetelnummer: true,
          sourceCode: true,
          inkoperPlaten: true,
          bewerken: true,
          delete: true,
          __check__: true,
        },
      },
    },
  },
  purchaserCards: {
    CACHE_KEY: 'purchaser-cards-datagrid' as const,
    initialState: {
      columns: {
        columnVisibilityModel: {
          koperPlaatNummer: true,
          koperNummer: true,
          koperNaam: true,
          aalsmeer: true,
          naaldwijk: true,
          rijnsburg: true,
          eelde: true,
          delete: true,
          __check__: true,
        },
      },
    },
  },
  purchaserLimits: {
    CACHE_KEY: 'purchaser-limits-datagrid' as const,
    initialState: {
      columns: {
        columnVisibilityModel: {
          userAccountId: true,
          userAccountName: true,
          customerAdministrationNumber: true,
          customerName: true,
          userAccountDayLimitAmount: true,
          startDateTime: true,
          endDateTime: true,
          isUserAccountBlocked: true,
          blockedDate: true,
          bewerken: true,
          __check__: true,
        },
      },
    },
  },
}
