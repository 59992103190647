export type FeatureName = 'INKOPERLIMIETEN'

export type Feature = {
  name: FeatureName
  enabled: boolean
  alwaysEnabled?: boolean
}

export const FEATURES = {
  INKOPERLIMIETEN: {
    name: 'INKOPERLIMIETEN' as FeatureName,
    enabled: false,
    alwaysEnabled: true,
  },
}

export const featureList: Feature[] = Object.values(FEATURES)
export const featureNames = Object.keys(FEATURES) as FeatureName[]
